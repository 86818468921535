.site-search-open .site-search-toggle .icon-search {
  display: none;
}

.site-search-open .site-search-toggle .icon-cross {
  display: inline-block;
}

.site-search-toggle .icon-cross {
  display: none;
}

#js-site-search.site-search {
  background-color: var(--color-background-main);
  display: none;
}

.site-search-open #js-site-search.site-search {
  background: var(--color-background-main);
  color: inherit;
  display: block;
  left: 0;
  margin: 0;
  padding-block: 0.5rem;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: var(--z2);
}

.search {
  align-items: stretch;
  display: flex;
  position: relative;
  width: 100%;
}

.search-input {
  border-radius: 2rem;
  box-shadow: inset 25px 0px 25px -25px rgba(0, 0, 0, 0.35),
    inset -25px 0px 25px -25px rgba(0, 0, 0, 0.35);
  padding-inline-start: 2rem;
}

.search .search-input {
  border: 2px solid var(--color-primary);
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  flex: 1 1 auto;
  max-width: 100%;
}

.search .search-button {
  border-bottom-left-radius: 0;
  border-left: 0;
  border-top-left-radius: 0;
  flex: 0 1 150px;
  margin: 0;
  min-width: 100px;
}

/* .search .search-button,
.search .results-search-button {
  background-color: var(--color-secondary);
  color: var(--color-primary);
} */
/* 
.search .search-button:focus,
.search .search-button:hover,
.search .results-search-button:focus,
.search .results-search-button:hover {
  background-color: var(--color-primary);
  color: var(--color-secondary);
} */

.search-results {
  background: var(--color-background-main);
  border: 2px solid var(--color-primary);
  border-top: 0;
  box-shadow: inset 25px 0px 25px -25px rgba(0, 0, 0, 0.35),
    inset -25px 0px 25px -25px rgba(0, 0, 0, 0.35);
  display: none;
  left: 0;
  position: absolute;
  right: 0;
  text-align: left;
  z-index: var(--z3);
}

#js-site-search .search-results {
  top: 100%;
}

.search-results .results-list {
  max-height: 15rem;
  overflow-y: auto;
}

.search-results .results-list a {
  display: block;
  font-size: 1.1rem;
  padding: 0.5rem 2rem;
  /* margin-inline: 3px; */
  text-decoration: none;
}

.search-results .results-list a:focus,
.search-results .results-list a:hover {
  background: var(--color-primary);
  color: var(--color-text-main--dark);
  outline: none;
}

.search.show-results .search-input {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.search .results-search-button {
  display: block;
  margin: 1.5rem auto;
}

#js-site-search .search.show-results .results-search-button {
  display: none;
}

.search.show-results .search-button {
  border-bottom-right-radius: 0;
}

.search.show-results .search-results {
  display: block;
}

@media screen and (max-width: 899px) {
  .search.show-results .search-input {
    border-bottom-left-radius: 0 !important;
  }
}

@media screen and (min-width: 900px) {
  .search-results {
    top: 100%;
  }
  .search.show-results .results-search-button {
    display: none;
  }
}
