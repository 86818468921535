/* 
A stack is a composition in which spacing along the flow direction 
(vertical in horizontal-tb writing mode) is inserted between elements.

In case the stack component is supposed to be interpreted as a list for 
screen reader software, add `role="list"` to the element the `stack` class
is applied to.
*/

.stack {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.stack > * {
  margin-block: 0;
}

.stack > * + * {
  margin-block-start: var(--space, 1.5rem);
}

/* Don't apply the margin-block-start for elements following screen reader elements. */
.stack > .srt + * {
  margin-block-start: 0;
}

.stack--split-1 > :first-child,
.stack--split-2 > :nth-child(2),
.stack--split-3 > :nth-child(3) {
  margin-block-end: auto;
}

.stack--dense > * + * {
  margin-block-start: calc(var(--space, 1.5rem) / 3);
}

.stack--loose > * + * {
  margin-block-start: calc(var(--space, 1.5rem) * 2);
}

.stack--center {
  justify-content: center;
}

.stack--end {
  justify-content: flex-end;
}
