.fc .fc-button-primary {
  background-color: var(--color-background-button);
  border-color: var(--color-background-button);
}

.fc .fc-button-primary:hover,
.fc .fc-button-primary:not(:disabled).fc-button-active,
.fc .fc-button-primary:not(:disabled):active {
  background: var(--color-primary);
  border-color: var(--color-primary);
  color: var(--color-text-button);
}

.fc .fc-cell-shaded,
.fc .fc-day-disabled,
.fc .fc-theme-standard .fc-list-day-cushion {
  background-color: #f1f1f1;
}

.fc .fc-list-sticky .fc-list-day > * {
  z-index: var(--z4);
}

.fc .fc-button-primary:not(:disabled).fc-button-active:focus,
.fc .fc-button-primary:not(:disabled):active:focus {
  box-shadow: 0 0 0 0.2rem rgba(4, 75, 54, 0.5);
}

.fc-h-event {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
}

.fc-daygrid-dot-event {
  flex-wrap: wrap;
}

.fc-daygrid-dot-event .fc-event-title {
  text-overflow: ellipsis;
}

.fc-toolbar-chunk:first-child {
  display: flex;
  flex-wrap: wrap;
  gap: 0.75em;
}

.fc-toolbar-chunk:first-child > .fc-today-button {
  margin-left: 0;
}

.fc .fc-header-toolbar {
  gap: 0.75em;
}

@media screen and (max-width: 899px) {
  .fc .fc-header-toolbar {
    flex-wrap: wrap;
  }

  .fc-toolbar-chunk:first-child {
    flex: 1 0 100%;
  }
}

.event-list-entry {
  margin-block-end: 1rem;
}

.event-list-entry span {
  color: var(--color-text-muted);
  display: block;
  font-style: italic;
  line-height: 1.25rem;
}

.event-list-entry a {
  font-size: 1.1rem;
}
