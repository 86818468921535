/* Styles in Progress and Temporary Fixes */

/* Create generic class for text overlays? */
.text-overlay {
  background: var(--color-background-text-overlay, rgba(255, 255, 255, 0.5));
  color: var(--color-text-overlay, rgb(20, 20, 20));
  padding: var(--space, 1.5rem);
}

/* Create more flexible way of modifying spacing within a stack */
/* Used in base layout temporarily */
.stack--very-loose > * + * {
  margin-block-start: 5rem;
}

/* Put category filters at the bottom of the page on small screens for now */
@media screen and (max-width: 875px) {
  .category-filters {
    order: 1;
  }
}

/* Space out form elements on product detail pages */
.product-info .form__element {
  flex: 0 0 45%;
}

/* Thin border utility class */
.border-thin {
  border: 1px solid rgba(0, 0, 0, 0.25);
}

/* Remove margin-top from first child inside a utility hide-if-no-js container within a stack*/
.stack .hide-if-no-js > :first-child {
  margin-block-start: 0;
}

/* Restore link color in boxes */
.box a:not(.button) {
  color: var(--color-link);
}

/* More inline-start padding for box as ul to push the bullet points further away from the edge */
ul.box {
  padding-inline-start: var(--s2);
}

/* Decrease width of cart item form input fields */
.cart-item input,
.cart-item select {
  width: auto;
}

/* Individual styles for the address_choice form */
#address-choice-form #id_address_choice > div + div {
  margin-block-start: calc(var(--s1) / 3);
}
#address-choice-form #id_address_choice label:has(:not(:checked)) {
  font-weight: 400;
}
#address-choice-form #id_address_choice + .form__text {
  display: none;
}

/* Offset the toolbar on the search results page */
.has-toolbar.is-search-results-page #js-site-search.site-search {
  top: calc(100% + 2 * 1rem + 1.5rem);
}

/* Remove accidental extra margin from h1 inside a container as that is taken care of by the stack */
.container > h1:first-child {
  margin: 0;
}

/* Restrict the max-width of grid elements because many grids have only one element */
.grid.limited > * {
  max-inline-size: 600px;
}

/* 
Let the interactive map be at least 500px high when the sidebar layout 
stacks vertically. The 899px max-width breakpoint corresponds to the 900px
min-width breakpoint of the sidebar layout (layouts/_sidebar.css).
*/
/* @media screen and (max-width: 899px) {
  #js-interactive-map {
    min-block-size: 500px;
  }
} */

/* Override Leaflet's popup content margin (why is that even there?). */
.leaflet-popup-content p {
  margin: 0;
}
