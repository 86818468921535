/* 
The sidebar layout places two elements next to each other, with one being the
narrower sidebar and the other being the main content.
Below a certain breakpoint, the sidebar content will be placed below the main
content.
The order by default is non-sidebar - sidebar, but can be reversed by adding 
the `reverse` class to the `with-sidebar` element. The reversal of the elements
only applies for the side-by-side configuration, not the vertically stacked
configuration on smaller screens.
*/

.with-sidebar {
  --sidebar-gap: var(--gap, 1.5rem);
  display: flex;
  flex-direction: column;
  gap: var(--sidebar-gap);
  justify-content: space-between;
}

@media screen and (min-width: 900px) {
  .with-sidebar {
    flex-direction: row;
  }

  .with-sidebar > :first-child,
  .with-sidebar.sidebar-first > :last-child {
    flex: 1 1 var(--main-content-inline-size, 65%);
  }

  .with-sidebar > :last-child,
  .with-sidebar.sidebar-first > :first-child {
    flex: 1 1 var(--sidebar-inline-size, 15rem);
  }

  .with-sidebar.reverse {
    flex-direction: row-reverse;
  }
}
