/* 
The grid layout provides a grid structure but without a hard-coded size for
the grid elements. With the min function the grid automatically switches
from a one-column layout to a flexible grid layout at the specified width
in the calc function.
*/

.grid {
  display: grid;
  gap: var(--space, 1.5rem);
  /* Set the minimum column width */
  --max-columns: 4;
  /* Add 1 to the maximum number of columns to account for the grid gap 
     in a very approximate and broad way */
  --minimum: calc(var(--max-inline-size, 1400px) / (var(--max-columns) + 1));
  /* --minimum: 200px; */
}

.grid--lax {
  gap: calc(var(--space, 1.5rem) * 2);
}

@supports (width: min(50px, 100%)) {
  .grid {
    /* Use the min() function for multiple columns if supported */
    grid-template-columns: repeat(
      auto-fit,
      minmax(min(var(--minimum, 200px), 100%), 1fr)
    );
  }
}
