.form__element {
  margin-bottom: 1.5rem;
  max-width: var(--measure);
}

.form__element > label + .form__input {
  margin-block-start: 0.25rem;
}

input,
select {
  background: transparent;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 3rem;
  max-width: var(--measure);
  padding: 0.5em 0.75em;
  width: 100%;
}

/* input {
  border: 0;
  border-bottom: 1px solid var(--color-text-main-transparent);
  border-radius: 0;
  max-width: 100%;
  padding: 0.5rem 0.75rem;
  width: 100%;
} */

input[type='checkbox'],
input[type='radio'] {
  margin: 0;
  width: auto;
}

input:focus {
  border-color: var(--color-text-main);
}

label {
  font-weight: 700;
}

label.required::after {
  color: var(--color-error);
  content: '*';
  display: inline-block;
  font-weight: 700;
  margin-inline-start: 0.5em;
}

/* 
Hide the required indicator from specific fields by their ID.
These IDs are Python code dependent. 
*/
label[for='id_quantity'].required::after,
label[for='id_wishlist'].required::after {
  content: '' !important;
}
