.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-align-center {
  align-items: center !important;
}

.flex-align-end {
  align-items: flex-end !important;
}

.flex-align-self-start {
  align-self: flex-start !important;
}

.flex-align-start {
  align-items: flex-start !important;
}

.flex-justify-between {
  justify-content: space-between !important;
}

.flex-justify-center {
  justify-content: center !important;
}

.flex-justify-end {
  justify-content: flex-end !important;
}

.flex-justify-start {
  justify-content: flex-start !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}
