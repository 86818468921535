.pagination {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style-type: none !important;
  padding: 2.5rem 0;
}

.pagination .page-number {
  border: 1px solid var(--color-text-main);
  border-right: 0;
}

.pagination .page-number:last-child {
  border-right: 1px solid var(--color-text-main);
}

.pagination .page-number.current,
.pagination .page-number a {
  background: var(--color-grey);
  color: var(--color-text-main);
  display: block;
  padding: 0.5rem 1rem;
  text-decoration: none;
  text-transform: uppercase;
}

.pagination .page-number.current,
.pagination .page-number a:focus,
.pagination .page-number a:hover {
  background: var(--color-dark-grey);
}
